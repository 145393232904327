<template>
  <div>
    <!-- todo - https://stackoverflow.com/questions/50392918/vuetify-how-to-preselect-active-tab
     -->
    <v-tabs v-model="activetab" align-with-title>
      <v-tab  v-for="tab of tabs"  :key="tab.id" @click="WarnUser(tab.to)">
        <span>{{ tab.caption }}</span>
      </v-tab>
    </v-tabs>
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'SharedProgram',
  components: {
  },
  data: () => ({
    dialog: false,
    path: '',
    activetab: 0,
    tabs: [
      { id: 1, to: { path: '/shared/banks' }, caption: 'Banks' },
      { id: 2, to: { path: '/shared/funds' }, caption: 'Funds' },
      { id: 3, to: { path: '/shared/source' }, caption: 'Source (Beta)' },
      { id: 4, to: { path: '/shared/taxFund' }, caption: 'Tax Fund (Beta)' },
      { id: 5, to: { path: '/shared/taxUnit' }, caption: 'Tax Unit (Beta)' },
      { id: 6, to: { path: '/shared/taxDistrict' }, caption: 'Tax District (Beta)' },
      { id: 7, to: { path: '/shared/taxLevy' }, caption: 'Tax Levy (Beta)' },
    ],
  }),
  methods: {
    WarnUser(path) {
      this.path = path;
      const currentpath = this.$router.currentRoute.path;
      if (path.path !== currentpath) {
        this.$router.push(path);
      }
    },
  },
  computed: {
  },
};
</script>

<style>

</style>
